var LeadsQc = (function () {
  'use strict';

  //Basic Config
  var config = {
  };

  //Bind to contacted links
  $('a[data-qc-contacted]').on('click', function(e){
  	
  	e.preventDefault();

    var lead_id = $(this).attr('data-id');

    $.get('/leads/qc/contacted/' + lead_id, function(data){
    	console.log(data);

    	if (data.success == true) {
    		$('#lead' + lead_id).addClass('online');

    	}
    	else {
    		alert('That did not work');
    	}
    });

  });

  //Bind to verify links
  $('a[data-qc-verify]').on('click', function(e){
  	
  	e.preventDefault();

    var camp_id = $(this).attr('data-qc-verify');
    var lead_id = $(this).attr('data-id');

    $.get('/leads/qc/verify/' + lead_id + '/' + camp_id, function(data){
    	console.log(data);

    	if (data.success == true) {
    		$('#lead' + lead_id).fadeOut(500, function(){
    			$('#lead' + lead_id).remove();
    		});
    	}
    	else {
    		alert('That did not work');
    	}
    });

  });

  return {
    //General data
    conf: config,

    //Init function
    init: function (options) {
      
      //Extends basic config with options
      $.extend( config, options );

    }
  };
 
})();