var Vehicles = (function () {
  'use strict';

  //Basic Config
  var config = {
  };

  /**
   * Check that the user is logged in and then run the callback function provided
   */
  function checkSession(callback) {
    // Check for a valid session
    $.get("/session", function(result) {
      // Run the callback function provided
      if (result.session == true) {
        callback();
      }
      // If not logged in, redirect to login page
      else {
        location.href="/login";
      }
    });
  }

  function vehiclesInit() {

    // Bind to the filter select change
    $('.filter-select').on('change', function(){
      $('#filter_form').trigger('submit');
    });

    $('#btn-add-vehicle').on('click', function(e) {
      e.preventDefault();
      // Open the modal? Are we going to use a modal?
    });

    $('.btn-add-year').on('click', function(e) {
      e.preventDefault();

      var year = $(this).attr('data-year');
      var vehicle = $(this).attr('data-vehicle-id');

      var btn = $(this);

      checkSession(function() {
        $.get('/vehicles/create?year=' + year + '&vehicle=' + vehicle)
          .done(function (result) {
            if (result.success == true) {
              // Hide the button
              btn.hide();

              // Find the row
              var tr = btn.closest('tr');
              tr.removeClass('status-active').addClass('status-inactive');

              // Set the id for the edit button
              tr.find('.btn-edit').attr('data-id', result.vehicle_id);

              // Show the row variables
              tr.find('.hidden').each(function(i){
                $(this).removeClass('hidden');
              });
            }
            else {
              $.gritter.add({
                title: 'Warning:',
                text: result.message,
                class_name: 'color warning'
              });
            }
          })
          .fail(function (xhr, status, error) {
            $.gritter.add({
              title: 'Error:',
              text: 'We received an unexpected response from the server.',
              class_name: 'color danger'
            });
          });
      });

    });


  }

  return {
    //General data
    conf: config,

    //Init function
    init: function (options) {
      
      //Extends basic config with options
      $.extend( config, options );

      vehiclesInit();

    }
  };
 
})();