var Companies = (function () {
  'use strict';

  //Basic Config
  var config = {
  };

  function companiesInit() {
 		
 		// Bind to add company button
    $('#btn-add-company').on('click', function(e){
      e.preventDefault();
      $("#new-company").modal('show');    
    });

    // Bind to the filter select change
    $('.filter-select').on('change', function(){
      $('#filter_form').trigger('submit');
    });

  }

  return {
    //General data
    conf: config,

    //Init function
    init: function (options) {
      
      //Extends basic config with options
      $.extend( config, options );

      companiesInit();

    }
  };
 
})();