var Dashboard = (function () {
  'use strict';

  var config = {};

  var check_time;
  var inactive_id = false;

  function autoRefresh() {
    var check = new Date().getTime() - check_time;

    if (check >= 300000) {
      window.location.reload(true);
    } else if (check >= 290000) {
      inactive_id = $.gritter.add({
        title: 'Inactivity Warning',
        text: 'The dashboard will automatically refresh <strong>in 10 seconds</strong> unless you move your mouse or press any key.',
        class_name: 'color primary',
        sticky: true
      });
      setTimeout(autoRefresh, 10000);
    } else {
      setTimeout(autoRefresh, 10000);
    }
  }

  function autoRefreshInit() {
    check_time = new Date().getTime();
    $(document.body).bind("mousemove keypress", function (e) {
      check_time = new Date().getTime();
      if (inactive_id) {
        $.gritter.remove(inactive_id);
        inactive_id = false;
      }
    });
    setTimeout(autoRefresh, 1000);
  }

  function counterInit() {
    $('[data-toggle="counter"]').each(function (i, e) {
      var _el = $(this);
      var prefix = '';
      var suffix = '';
      var start = 0;
      var end = 0;
      var decimals = 0;
      var duration = 2.5;

      if (_el.data('prefix')) {
        prefix = _el.data('prefix');
      }

      if (_el.data('suffix')) {
        suffix = _el.data('suffix');
      }

      if (_el.data('start')) {
        start = _el.data('start');
      }

      if (_el.data('end')) {
        end = _el.data('end');
      }

      if (_el.data('decimals')) {
        decimals = _el.data('decimals');
      }

      if (_el.data('duration')) {
        duration = _el.data('duration');
      }

      var count = new CountUp(_el.get(0), start, end, decimals, duration, {
        suffix: suffix,
        prefix: prefix,
      });

      count.start();
    });
  }

  function fieldsInit() {
    $('.number input,.number select').on('change', function () {
      $(this).css('font-weight', 'bold');
    });
  }

  function toolsInit() {
    $('.toggle-complete').on('click', function () {
      $(this).toggleClass('danger').toggleClass('mdi-eye').toggleClass('mdi-eye-off');
      $(this).parent().find('.toggle-filter').toggle(0);
      $(this).closest('form').find('table tbody tr.complete').fadeToggle();
    });

    $('.toggle-filter').on('click', function () {
      $(this).toggleClass('primary');
      $(this).parent().find('.toggle-complete').toggle(0);
      $(this).closest('form').find('.filters').fadeToggle(function () {
        $(this).find('.filter-distributions').val('').trigger('keyup');
      }).find('input').focus();
    });

    $('.filter-distributions').on('keyup', function () {
      var val = $(this).val().toLowerCase();
      $(this).closest('form').find('table tbody tr:not(.filters) td:first-child').each(function () {
        var data = $(this).text();
        if (data.toLowerCase().indexOf(val) == -1) {
          $(this).parent().fadeOut();
        } else {
          $(this).parent().fadeIn();
        }
      });
    });
  }

  return {
    conf: config,

    init: function (options) {

      //Extends basic config with options
      $.extend(config, options);

      counterInit();
      fieldsInit();
      autoRefreshInit();
      toolsInit();

    }
  };

})();