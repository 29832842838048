var Billing = (function () {
  'use strict';

  //Basic Config
  var config = {
  };

  var hasLockWarning = false;

  function billingInit() {

    // Bind to the filter select change
    $('.filter-select').on('change', function(){
      $('#filter_form').trigger('submit');
    });

    // Handle locked fields
    $('.btn-lock').on('click', function(e){
      e.preventDefault();

      var input = $(this).closest('.form-group').find('input');

      if (input.prop('readonly')) {
        input.prop('readonly', false);
        $(this).children('i').removeClass('mdi-lock').addClass('mdi-lock-open');
        if (!hasLockWarning) {
          $.gritter.add({
            title: 'Warning',
            text: 'The value of locked fields should rarely need to be modified. If recurring changes are required, update the customer profile instead.',
            class_name: 'color warning',
            sticky: true
          });
          hasLockWarning = true;
        }
      }
      else {
        input.prop('readonly', true);
        $(this).children('i').removeClass('mdi-lock-open').addClass('mdi-lock');
      }

    });

  }

  return {
    //General data
    conf: config,

    //Init function
    init: function (options) {

      $.extend($.gritter.options, { position: 'bottom-right' });
      
      //Extends basic config with options
      $.extend( config, options );

      billingInit();

    },

    editBilling: function (queue_id, company_id, company_name) {

      $("#edit-billing #queue_id").val(queue_id);
      $("#edit-billing #company_id").val(company_id);
      $("#edit-billing #company_name").html(company_name);
      $("#edit-billing #message").val("");

      $("#edit-billing").modal('show');

      return false;
    },

    openPayment: function (queue_id, company_id, company_name, primary_method, backup_method, amount, description, receipts, retry) {

      $("#new-payment #queue_id").val(queue_id);
      $("#new-payment #company_id").val(company_id);
      $("#new-payment #company_name").html(company_name);
      $("#new-payment #primary_method").val(primary_method);
      $("#new-payment #backup_method").val(backup_method);
      $("#new-payment #amount").val(amount);
      $("#new-payment #description").val(description);

      if (receipts) {
        $("#new-payment #receipts").show();
      }
      else {
        $("#new-payment #receipts").hide();
      }

      if (retry) {
        $("#new-payment #retry").show();
      }
      else {
        $("#new-payment #retry").hide();
      }

      $("#new-payment").modal('show');

      return false;
    }
  };
 
})();