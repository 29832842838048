var Calls = (function () {
  'use strict';

  //Basic Config
  var config = {
  };

  /**
   * Check that the user is logged in and then run the callback function provided
   */
  function checkSession(callback) {
    // Check for a valid session
    $.get("/session", function(result) {
      // Run the callback function provided
      if (result.session == true) {
        callback();
      }
      // If not logged in, redirect to login page
      else {
        location.href="/login";
      }
    });
  }

  function callsInit() {

    // Bind to the filter select change
    $('.filter-select').on('change', function(){
      $('#filter_form').trigger('submit');
    });

    $(".datetimepicker").datetimepicker({
      autoclose: true,
      componentIcon: '.mdi.mdi-calendar',
      todayBtn: true,
      navIcons:{
        rightIcon: 'mdi mdi-chevron-right',
        leftIcon: 'mdi mdi-chevron-left'
      }
    })
    .on('changeDate', function(e){
      //console.log($(this), $(this).hasClass('filter'));
      if ($(this).hasClass('filter')) {
        $('#filter_form').trigger('submit');
      }
    })
    .on('change', function(e){
      if ($(this).hasClass('filter') && $(this).val() == '') {
        $('#filter_form').trigger('submit');
      }
    });

  }

  return {
    //General data
    conf: config,

    //Init function
    init: function (options) {
      
      //Extends basic config with options
      $.extend( config, options );
      callsInit();
    }

  };
 
})();