var Contacts = (function () {
  'use strict';

  //Basic Config
  var config = {
  };

  function contactsInit() {

    // Bind to the filter select change
    $('.filter-select').on('change', function(){
      $('#filter_form').trigger('submit');
    });

  }

  return {
    //General data
    conf: config,

    //Init function
    init: function (options) {
      
      //Extends basic config with options
      $.extend( config, options );

      contactsInit();

    }
  };
 
})();