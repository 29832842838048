var Reports = (function () {
  'use strict';

  // Basic Config
  var config = {};

  function enableSubmitButton() {
    $("#report_submit").removeAttr('disabled');
    $("#report_submit img").hide();
    $("#report_submit span").show();
  }

  function disableSubmitButton() {
    $("#report_submit").attr('disabled', 'disabled');
    $("#report_submit span").hide();
    $("#report_submit img").show();
  }

  /**
   * Check that the user is logged in and then run the callback function provided
   */
  function checkSession(callback) {
    // Check for a valid session
    $.get("/session", function (result) {
      // Run the callback function provided
      if (result.session == true) {
        callback();
      }
      // If not logged in, redirect to login page
      else {
        location.href = "/login";
      }
    });
  }

  function setupForm(type, name, callback=false) {

    $('#report_form').parsley()
      .on('form:success', function () {
        disableSubmitButton();
        $('#report_data').empty();
        $('#report_results').hide();

        checkSession(function() {
          $.post('/reports/' + type, $('#report_form').serialize())
            .done(function (result) {
              if (result.success == true) {
                // Fill the data
                $('#report_title').html(result.title);
                $('#report_data').html(result.data);
                $('#report_results').show();
                if (callback !== false) {
                  callback();
                }
              }
              else {
                showAlertMessage(result.message);
              }

              enableSubmitButton();
            })
            .fail(function (xhr, status, error) {
              showErrorMessage("We received an unexpected response from the server.");
              enableSubmitButton();
            });
        });
      })
      .on('form:submit', function () {
        return false;
      });
  }

  /**
   * Initialize the billing report
   */
  function initBilling() {
    setupForm('billing', 'Billing Report');
  }

  /**
   * Initialize the competitor analysis
   */
  function initCompetitor() {
    setupForm('competitor', 'Competitor Analysis');
  }

  /**
   * Initialize the delivery report
   */
  function initDelivery(id) {
    setupForm('delivery', 'Delivery Report');
  }

  /**
   * Initialize the keyword performance
   */
  function initKeyword(id) {

  }

  /**
   * Initialize the lead sales
   */
  function initSales(id) {
    setupForm('sales', 'Lead Sales');
  }

  /**
   * Initialize the route analysis
   */
  function initRoutes(id) {
    setupForm('routes', 'Route Analysis', function(){
      $('.table-small td').hover(function(){
        $(this).parents('tr').toggleClass('hover');
        $(this).parents('table').find('tr').find('td:eq('+($(this).index() - 1)+')').toggleClass('hover');
        $(this).parents('table').find('tr').find('th:eq('+($(this).index())+')').toggleClass('hover');
      });
    });



  }

  function showAlertMessage(message) {
    $.gritter.add({
      title: 'Warning:',
      text: message,
      class_name: 'color warning'
    });
  }

  function showErrorMessage(message) {
    $.gritter.add({
      title: 'Error:',
      text: message,
      class_name: 'color danger'
    });
  }

  return {
    //General data
    conf: config,

    //Init function
    init: function (type, options) {

      config.loaded = [];

      //Extends basic config with options
      $.extend(config, options);
      $.extend($.gritter.options, {position: 'bottom-right'});

      $(".datetimepicker").datetimepicker({
        autoclose: true,
        componentIcon: '.mdi.mdi-calendar',
        navIcons: {
          rightIcon: 'mdi mdi-chevron-right',
          leftIcon: 'mdi mdi-chevron-left'
        }
      });

      $('[data-toggle="tooltip"]').tooltip();
      $('[data-toggle="popover"]').popover();

      switch (type) {
        case 'billing':
          initBilling();
          break;
        case 'competitor':
          initCompetitor();
          break;
        case 'delivery':
          initDelivery();
          break;
        case 'keyword':
          initKeyword();
          break;
        case 'sales':
          initSales();
          break;
        case 'routes':
          initRoutes();
          break;
        default:
          // Show an error to the user
          $.gritter.add({
            title: 'Error:',
            text: 'The requested report is not fully configured.',
            class_name: 'color danger'
          });
      }
    }
  };

})();

