var Company = (function () {
  'use strict';

  // Basic Config
  var config = {};

  /**
   * Check that the user is logged in and then run the callback function provided
   */
  function checkSession(callback) {
    // Check for a valid session
    $.get("/session", function (result) {
      // Run the callback function provided
      if (result.session == true) {
        callback();
      }
      // If not logged in, redirect to login page
      else {
        location.href = "/login";
      }
    });
  }

  /**
   * Initialize the accounting tab
   */
  function initAccounting(id) {

    // Bind to the add new card link
    $('#btn-accounting-new-card').on('click', function (e) {
      e.preventDefault();
      $('#new-card').modal();
    });

    // Bind to the change default card link
    $('#btn-accounting-change-default').on('click', function (e) {
      e.preventDefault();
      $('#change-card').modal();
    });

    // Bind to the refresh card link
    $('#btn-accounting-refresh-cards').on('click', function (e) {
      e.preventDefault();
      $("#accounting-cards").html('');
      checkSession(function () {
        loadAccountingCards(id);
      });
    });

    // Bind to the new payment link
    $('#btn-accounting-new-payment').on('click', function (e) {
      e.preventDefault();
      $('#new-payment').modal();
    });

    // Bind to the refresh payments link
    $('#btn-accounting-refresh-charges').on('click', function (e) {
      e.preventDefault();
      $("#accounting-charges").html('');
      checkSession(function () {
        loadAccountingCharges(id);
      });
    });

    // Bind to the new transaction link
    $('#btn-accounting-new-transaction').on('click', function (e) {
      e.preventDefault();
      $('#new-transaction').modal();
    });

    // Bind to the refresh transactions link
    $('#btn-accounting-refresh-transactions').on('click', function (e) {
      e.preventDefault();
      $("#accounting-transactions").html('');
      checkSession(function () {
        loadAccountingTransactions(id);
      });
    });

    $('#transactions-segment').on('change', function (e) {
      e.preventDefault();
      $('#btn-accounting-refresh-transactions').trigger('click');
    });

    $('#new-payment form').on('submit', function (e) {
      $('#new-payment-submit').attr('disabled', 'disabled');
    });

    $('#payment-card').on('change', function (e) {
      var note = $('#payment-card option:selected').attr("data-note");
      $('#payment-note').html(note);
    });

    $('#transaction-type').on('change', function (e) {
      var type = $(this).val();
      var desc = "";

      if (type.substring(0, 6) == 'credit') {
        switch (type.substring(7, 14)) {
          case 'general':
            desc = "Account Credit";
            break;
          case '5':
            desc = "Credit for X Calls";
            break;
          default:
            desc = "Credit for X Leads";
        }
      } else {
        switch (type.substring(7, 14)) {
          case 'general':
            desc = "Account Charge";
            break;
          case 'payment':
            desc = "Payment Received {insert charge reference ID}";
            break;
          case '5':
            desc = "Weekly - X Calls @ $0.00";
            break;
          default:
            desc = "X Leads @ $0.00";
        }
      }

      $('#transaction-description').val(desc);
    });

  }

  /**
   * Initialize the company billing profile
   */
  function initBilling(id) {

    // Bind to the edit billing profile button
    $('#btn-company-billing-edit').on('click', function (e) {
      e.preventDefault();
      $("#edit-billing").modal('show');
    });

    // Bind to the frequency change
    $('#billing-frequency').on('change', function (e) {
      switch ($(this).val()) {
        case 'auto':
          $(".billing-daily,.billing-weekly,.billing-monthly").hide();
          $(".billing-auto").show();
          break;
        case 'daily':
          $(".billing-auto,.billing-weekly,.billing-monthly").hide();
          $(".billing-daily").show();
          break;
        case 'weekly':
          $(".billing-auto,.billing-daily,.billing-monthly").hide();
          $(".billing-weekly").show();
          break;
        case 'monthly':
          $(".billing-auto,.billing-daily,.billing-weekly").hide();
          $(".billing-monthly").show();
          break;
      }
    });

    config.loaded.billing = true;
  }

  /**
   * Initialize the campaigns tab
   */
  function initCampaigns(id) {

    // Bind to the add new distribution link
    $('#btn-campaigns-new-distribution').on('click', function (e) {
      e.preventDefault();
      $('#new-distribution').modal();
    });

    // Bind to the add new card link
    $('#btn-campaigns-new-signature').on('click', function (e) {
      e.preventDefault();
      $('#new-signature').modal();
    });

    $('#btn-campaigns-refresh-distributions').on('click', function (e) {
      e.preventDefault();
      $("#campaigns-distributions").html('');
      checkSession(function () {
        loadCampaignDistributions(id);
      });
    });

    $('#btn-campaigns-refresh-signatures').on('click', function (e) {
      e.preventDefault();
      $("#campaigns-signatures").html('');
      checkSession(function () {
        loadCampaignSignatures(id);
      });
    });

    $('#distribution-campaign-id').on('change', function (e) {
      e.preventDefault();

      var val = $(this).val();

      // Check if it's a call campaign
      if (val == '5') {
        $("#new-distribution-options").hide();
        $("#new-distribution-options-phone").show();
        $(".phone-required").attr('required', 'required');
        $("#filler-distribution").hide();
      } else {
        $("#new-distribution-options-phone").hide();
        $("#new-distribution-options").show();
        $(".phone-required").removeAttr('required');
        $("#filler-distribution").show();
      }

      // Just in case we need to reset parsley validation so that errors dont show from switching the campaign
      $("#new-distribution form").parsley().destroy();
      $("#new-distribution form").parsley();
    });

    $('.period').on('change', function (e) {
      var val = $(this).val();
      var label = $(this).attr('data-label');
      $('#' + label).html(val);
    });

    // Bind to the delete distribution button
    $('.btn-delete-distribution').on('click', function (e) {
      $('#form-delete-distribution').submit();
    });

  }

  /**
   * Initialize the company information
   */
  function initCompany(id) {
    // Bind to the edit company button
    $('#company-edit').on('click', function (e) {
      e.preventDefault();

      $(this).hide();
      $('.company-info').hide();
      $('.company-edit').show();
    });

    // Bind to the edit company cancel buttons
    $('#company-edit-cancel').on('click', function (e) {
      e.preventDefault();

      $('#company-edit-form')[0].reset();

      $('#company-edit').show();
      $('.company-edit').hide();
      $('.company-info').show();
    });

  }

  /**
   * Initialize the crm tab
   */
  function initCrm(id) {

    // Bind to the new contacct link
    $('#btn-crm-new-contact').on('click', function (e) {
      e.preventDefault();
      $('#new-contact').modal();
    });

    // Bind to the refresh users button
    $('#btn-crm-refresh-contacts').on('click', function (e) {
      e.preventDefault();
      $("#crm-contacts").html('');
      checkSession(function () {
        loadCompanyUsers(id);
      });
    });

    config.loaded.contact = true;

    // Bind to the note type
    $('.note-type').on('change', function (e) {
      var type = $(this).val();
      $(this).closest('.colored-header').removeClass('colored-header-primary colored-header-warning colored-header-success colored-header-danger').addClass('colored-header-' + type);
      $(this).closest('.modal').find('button[type="submit"]').removeClass('btn-primary btn-warning btn-success btn-danger').addClass('btn-' + type);
    });

    // Bind to the add new note link
    $('#btn-crm-new-note').on('click', function (e) {
      e.preventDefault();
      $('#new-note').modal();
    });

    // Bind to the refresh notes link
    $('#btn-crm-refresh-notes').on('click', function (e) {
      e.preventDefault();
      $("#crm-notes").html('');
      checkSession(function () {
        loadCrmNotes(id);
      });
    });

    $('#new-note form').on('submit', function (e) {
      $('#new-note-submit').attr('disabled', 'disabled');
    });

    // Bind to the add new reminder link
    $('#btn-crm-new-reminder').on('click', function (e) {
      e.preventDefault();
      $('#new-reminder').modal();
    });

    // Bind to the refresh reminders link
    $('#btn-crm-refresh-reminders').on('click', function (e) {
      e.preventDefault();
      $("#crm-reminders").html('');
      checkSession(function () {
        loadCrmReminders(id);
      });
    });

    $('#new-reminder form').on('submit', function (e) {
      $('#new-reminder-submit').attr('disabled', 'disabled');
    });

    // Bind to the CRM sales field changes
    $('#sales_status').on('change', function (e) {
      checkSession(function () {
        saveSalesData(id);
      });
    });

    $('#sales_note').on('focusout', function (e) {
      if ($('#sales_note').val() != $('#sales_note').attr('data-original')) {
        checkSession(function () {
          saveSalesData(id);
          $('#sales_note').attr('data-original', $('#sales_note').val());
        });
      }
    });

    $('#company_note').on('focusout', function (e) {
      if ($('#company_note').val() != $('#company_note').attr('data-original')) {
        checkSession(function () {
          saveSalesData(id);
          $('#company_note').attr('data-original', $('#company_note').val());
        });
      }
    });

  }

  /**
   * Initialize the company profile
   */
  function initProfile(id) {

    // Bind to the edit company button
    $('#btn-company-profile-edit').on('click', function (e) {
      e.preventDefault();
      $("#edit-profile").modal('show');
    });

    // Bind to the flag toggle
    $('#btn-company-profile-flag').on('change', function (e) {
      e.preventDefault();
      var id = $(this).val();

      checkSession(function () {
        $.get("/flags/toggle/" + id, function (data) {
          if (data.success == false) {
            $.gritter.add({title: 'Error:', text: data.message, class_name: 'color danger'});
            return false;
          }

          $.gritter.add({
            title: 'Success!',
            text: 'Your changes have been saved.',
            image: '/img/seal.png',
            class_name: 'clean img-round',
            time: 2000
          });
          return true;
        })
          .fail(function () {
            // Show an error to the user
            $.gritter.add({
              title: 'Error:',
              text: 'There was an error setting the company flag.',
              class_name: 'color danger'
            });
          });
      });
    });

    config.loaded.profile = true;
  }

  /**
   * Load the accounting cards for the given company
   */
  function loadAccountingCards(id) {
    // Pull all cards for this company
    $.get("/cards", {company_id: id}, function (data) {
      // Fill the card panel
      $("#accounting-cards").html(data);
      config.loaded.card = true;
    })
      .fail(function () {
        config.loaded.card = false;
        // Show an error to the user
        $.gritter.add({
          title: 'Error:',
          text: 'There was an error loading the accounting cards panel.',
          class_name: 'color danger'
        });
      });
  }

  /**
   * Load the accounting payments for the given company
   */
  function loadAccountingCharges(id) {
    // Pull all cards for this company
    $.get("/charges", {company_id: id}, function (data) {
      // Fill the card panel
      $("#accounting-charges").html(data);
      config.loaded.charge = true;
    })
      .fail(function () {
        config.loaded.charge = false;
        // Show an error to the user
        $.gritter.add({
          title: 'Error:',
          text: 'There was an error loading the accounting payments panel.',
          class_name: 'color danger'
        });
      });
  }

  /**
   * Load the accounting transactions for the given company
   */
  function loadAccountingTransactions(id) {
    // Pull all transactions for this company
    $.get("/transactions", {company_id: id, segment: $("#transactions-segment").val()}, function (data) {
      // Fill the transaction panel
      $("#accounting-transactions").html(data);
      config.loaded.transaction = true;
    })
      .fail(function () {
        config.loaded.transaction = false;
        // Show an error to the user
        $.gritter.add({
          title: 'Error:',
          text: 'There was an error loading the accounting transactions panel.',
          class_name: 'color danger'
        });
      });
  }

  /**
   * Load the campaign distributions for the given company
   */
  function loadCampaignDistributions(id) {
    // Pull all distributions for this company
    $.get("/distributions", {company_id: id}, function (data) {
      // Fill the distributions panel
      $("#campaigns-distributions").html(data);
      config.loaded.distribution = true;
    })
      .fail(function () {
        config.loaded.distribution = false;
        // Show an error to the user
        $.gritter.add({
          title: 'Error:',
          text: 'There was an error loading the campaign distributions panel.',
          class_name: 'color danger'
        });
      });
  }

  /**
   * Load the campaign signatures for the given company
   */
  function loadCampaignSignatures(id) {
    // Pull all signatures for this company
    $.get("/signatures", {company_id: id}, function (data) {
      // Fill the signatures panel
      $("#campaigns-signatures").html(data);
      config.loaded.signature = true;
    })
      .fail(function () {
        config.loaded.signature = false;
        // Show an error to the user
        $.gritter.add({
          title: 'Error:',
          text: 'There was an error loading the campaign signatures panel.',
          class_name: 'color danger'
        });
      });
  }

  /**
   * Load the users for the given company
   */
  function loadCompanyUsers(id) {
    // Pull all users for this company
    $.get("/users", {company_id: id}, function (data) {
      // Fill the users panel
      $("#crm-contacts").html(data);
      config.loaded.contact = true;
    })
      .fail(function () {
        config.loaded.contact = false;
        // Show an error to the user
        $.gritter.add({
          title: 'Error:',
          text: 'There was an error loading the company contacts panel.',
          class_name: 'color danger'
        });
      });
  }

  /**
   * Load the crm notes for the given company
   */
  function loadCrmNotes(id) {
    // Pull all notes for this company
    $.get("/notes", {company_id: id}, function (data) {
      // Fill the notes panel
      $("#crm-notes").html(data);
      config.loaded.notes = true;
    })
      .fail(function () {
        config.loaded.notes = false;
        // Show an error to the user
        $.gritter.add({
          title: 'Error:',
          text: 'There was an error loading the CRM notes panel.',
          class_name: 'color danger'
        });
      });
  }

  /**
   * Load the crm reminders for the given company
   */
  function loadCrmReminders(id) {
    // Pull all reminders for this company
    $.get("/reminders", {company_id: id}, function (data) {
      // Fill the reminders panel
      $("#crm-reminders").html(data);
      config.loaded.reminder = true;
    })
      .fail(function () {
        config.loaded.reminder = false;
        // Show an error to the user
        $.gritter.add({
          title: 'Error:',
          text: 'There was an error loading the CRM reminders panel.',
          class_name: 'color danger'
        });
      });
  }

  /**
   * Save the crm sales data via ajax
   */
  function saveSalesData(id) {
    $.get("/sales/update", {
      company_id: id,
      sales_status: $('#sales_status').val(),
      sales_note: $('#sales_note').val()
    }, function (data) {
      if (data.success) {
        $.gritter.add({
          title: 'Success!',
          text: 'Your changes have been saved.',
          image: '/img/seal.png',
          class_name: 'clean img-round',
          time: 2000
        });
      } else {
        $.gritter.add({title: 'Error:', text: data.message, class_name: 'color danger'});
      }

    })
      .fail(function () {
        // Show an error to the user
        $.gritter.add({
          title: 'Error:',
          text: 'There was an error saving the CRM information.',
          class_name: 'color danger'
        });
      });
  }

  return {
    //General data
    conf: config,

    //Init function
    init: function (options) {

      config.loaded = [];

      //Extends basic config with options
      $.extend(config, options);
      $.extend($.gritter.options, {position: 'bottom-right'});

      $(".datetimepicker").datetimepicker({
        autoclose: true,
        componentIcon: '.mdi.mdi-calendar',
        navIcons: {
          rightIcon: 'mdi mdi-chevron-right',
          leftIcon: 'mdi mdi-chevron-left'
        }
      });

      var id = $("#company_id").val();

      // Order by tab priority
      initProfile(id);
      initBilling(id);
      initCompany(id); // This is getting removed soon
      initCrm(id);
      initCampaigns(id);
      initAccounting(id);

      // If we have a valid session, run the following function
      checkSession(function () {
        loadCrmReminders(id);
        loadCrmNotes(id);
        loadCampaignDistributions(id);
        loadCampaignSignatures(id);
        loadAccountingCards(id);
        loadAccountingCharges(id);
        loadAccountingTransactions(id);
      });

    },


    // Delete the note
    deleteNote: function (note_id) {
      $('#delete_note_id').val(note_id);
      $('#delete-note').modal('show');
      return false;
    },

    deleteNoteConfirm: function () {
      var id = $('#delete_note_id').val();
      $('#note-delete' + id).trigger('submit');
      return false;
    },

    // Get the card details and open the edit modal
    editCard: function (card_id) {
      var id = card_id;
      checkSession(function () {
        // Pull the card info
        $.get("/cards/" + id + "/edit", function (data) {

          if (data.success == false) {
            $.gritter.add({title: 'Error:', text: data.message, class_name: 'color danger'});
            return false;
          }

          // Fill the card modal
          $("#edit-card form").attr('action', '/cards/' + data.card.id);

          // Clear all the fields just in case
          $("#edit-card form")[0].reset();

          // Just in case we need to reset parsley validation so that errors dont show from the last modal
          $("#edit-card form").parsley().destroy();
          $("#edit-card form").parsley();

          // Fill data
          $("#edit-card form #card-card_id").html(data.card.card_id);
          $("#edit-card form #card-note").val(data.card.note);

          $("#edit-card").modal('show');

        })
          .fail(function () {
            // Show an error to the user
            $.gritter.add({
              title: 'Error:',
              text: 'There was an error loading the requested card.',
              class_name: 'color danger'
            });
          });

      });
      return false;
    },

    // Get the distribution details and open the edit modal
    editDistribution: function (distribution_id, phone) {
      var id = distribution_id;
      var isPhone = typeof (phone) == 'undefined' ? false : phone;
      checkSession(function () {
        // Pull the distribution
        $.get("/distributions/" + id + "/edit", function (data) {
          if (data.success == false) {
            $.gritter.add({title: 'Error:', text: data.message, class_name: 'color danger'});
            return false;
          }

          // Check if the distribution can be deleted
          if (data.deletable == false) {
            $(".btn-delete-distribution").hide();
          } else {
            $(".btn-delete-distribution").show();
          }

          // Fill the distribution modal
          $("#edit-distribution" + (isPhone ? '-phone' : '') + " form").attr('action', '/distributions/' + data.distribution.id);
          $("#form-delete-distribution").attr('action', '/distributions/' + data.distribution.id);

          // Clear all the fields just in case
          $("#edit-distribution" + (isPhone ? '-phone' : '') + " form")[0].reset();

          // Just in case we need to reset parsley validation so that errors dont show from the last modal
          $("#edit-distribution" + (isPhone ? '-phone' : '') + " form").parsley().destroy();
          $("#edit-distribution" + (isPhone ? '-phone' : '') + " form").parsley();

          // Empty the changes table
          $("#edit-distribution" + (isPhone ? '-phone' : '') + " form #changes tbody").empty();

          $.each(data.distribution, function (index, value) {
            if (index == 'campaign') {
              $("#edit-distribution" + (isPhone ? '-phone' : '') + " form #distribution-" + index).html(value);
            } else if (index == 'phone-show-800' || index == 'phone-send-email' || index == 'phone-special' || index.substring(0, 13) == 'phone-exclude') {
              $("#edit-distribution" + (isPhone ? '-phone' : '') + " form #distribution-" + index).prop("checked", value);
            } else if (index == 'changes') {
              $.each(value, function (index, row) {
                $("#edit-distribution" + (isPhone ? '-phone' : '') + " form #changes tbody").append('<tr><td>' + row.user + '</td><td>' + row.date + '</td><td><a href="#" onclick="return Company.filterChanges(\'' + row.field + '\',' + isPhone + ');">' + row.field + '</a></td><td>' + row.change + '</td></tr>');
              });
            } else if (index == 'criteria') {
              $.each(value, function (index, value) {
                if (index == 'days') {
                  $.each(value, function (key, day) {
                    $("#edit-distribution" + (isPhone ? '-phone' : '') + " form #criteria" + (isPhone ? '-phone' : '') + "-day" + day).prop("checked", true);
                  });
                } else if (index == 'interstate_only' || index == 'running_only') {
                  $("#edit-distribution form #criteria-" + index).prop("checked", value);
                } else {
                  $("#edit-distribution" + (isPhone ? '-phone' : '') + " form #criteria-" + index).val(value);
                }
              });
            } else {
              if (index == 'period') {
                $("#edit-distribution" + (isPhone ? '-phone' : '') + " form #edit-period-label").html(value);
              }
              $("#edit-distribution" + (isPhone ? '-phone' : '') + " form #distribution-" + index).val(value);
            }
          });

          $("#edit-distribution" + (isPhone ? '-phone' : '')).modal('show');

        })
          .fail(function () {
            // Show an error to the user
            $.gritter.add({
              title: 'Error:',
              text: 'There was an error loading the requested distribution.',
              class_name: 'color danger'
            });
          });

      });
      return false;
    },

    // Get the note details and open the edit modal
    editNote: function (note_id) {
      var id = note_id;
      checkSession(function () {
        // Pull the note info
        $.get("/notes/" + id + "/edit", function (data) {

          if (data.success == false) {
            $.gritter.add({title: 'Error:', text: data.message, class_name: 'color danger'});
            return false;
          }

          // Fill the note modal
          $("#edit-note form").attr('action', '/notes/' + data.note.id);

          // Clear all the fields just in case
          $("#edit-note form")[0].reset();

          // Just in case we need to reset parsley validation so that errors dont show from the last modal
          $("#edit-note form").parsley().destroy();
          $("#edit-note form").parsley();

          // Fill data
          $.each(data.note, function (index, value) {
            if (index == 'created_at') {
              $("#edit-note #note-" + index).html(value);
            } else {
              $("#edit-note form #note-" + index).val(value);
            }
          });

          $("#edit-note .note-type").trigger('change');

          $("#edit-note").modal('show');

        })
          .fail(function () {
            // Show an error to the user
            $.gritter.add({
              title: 'Error:',
              text: 'There was an error loading the requested note.',
              class_name: 'color danger'
            });
          });

      });
      return false;
    },

    // Get the reminder details and open the edit modal
    editReminder: function (reminder_id) {
      var id = reminder_id;
      checkSession(function () {
        // Pull the reminder info
        $.get("/reminders/" + id + "/edit", function (data) {

          if (data.success == false) {
            $.gritter.add({title: 'Error:', text: data.message, class_name: 'color danger'});
            return false;
          }

          // Fill the reminder modal
          $("#edit-reminder form").attr('action', '/reminders/' + data.reminder.id);

          // Clear all the fields just in case
          $("#edit-reminder form")[0].reset();

          // Just in case we need to reset parsley validation so that errors dont show from the last modal
          $("#edit-reminder form").parsley().destroy();
          $("#edit-reminder form").parsley();

          // Fill data
          $.each(data.reminder, function (index, value) {
            $("#edit-reminder form #reminder-" + index).val(value);
          });

          $("#edit-reminder").modal('show');

        })
          .fail(function () {
            // Show an error to the user
            $.gritter.add({
              title: 'Error:',
              text: 'There was an error loading the requested reminder.',
              class_name: 'color danger'
            });
          });

      });
      return false;
    },

    // Get the signature details and open the edit modal
    editSignature: function (signature_id) {
      var id = signature_id;
      checkSession(function () {
        // Pull the signature info
        $.get("/signatures/" + id + "/edit", function (data) {

          if (data.success == false) {
            $.gritter.add({title: 'Error:', text: data.message, class_name: 'color danger'});
            return false;
          }

          // Fill the signature modal
          $("#edit-signature form").attr('action', '/signatures/' + data.signature.id);

          // Clear all the fields just in case
          $("#edit-signature form")[0].reset();

          // Just in case we need to reset parsley validation so that errors dont show from the last modal
          $("#edit-signature form").parsley().destroy();
          $("#edit-signature form").parsley();

          // Fill data
          $.each(data.signature, function (index, value) {
            if (index == 'options') {
              $.each(value, function (key, val) {
                //console.log(key, val);
                if (val === true) {
                  $("#edit-signature #signature-" + key).prop("checked", true);
                }
              });
            } else {
              $("#edit-signature form #signature-" + index).val(value);
            }
          });

          $("#edit-signature").modal('show');

        })
          .fail(function () {
            // Show an error to the user
            $.gritter.add({
              title: 'Error:',
              text: 'There was an error loading the signature request.',
              class_name: 'color danger'
            });
          });

      });
      return false;
    },

    // Get the user details and open the edit modal
    editContact: function (user_id) {
      var id = user_id;
      checkSession(function () {
        // Pull the user info
        $.get("/users/" + id + "/edit", function (data) {

          if (data.success == false) {
            $.gritter.add({title: 'Error:', text: data.message, class_name: 'color danger'});
            return false;
          }

          // Fill the user modal
          $("#edit-contact form").attr('action', '/users/' + data.user.id);

          // Clear all the fields just in case
          $("#edit-contact form")[0].reset();

          // Just in case we need to reset parsley validation so that errors dont show from the last modal
          $("#edit-contact form").parsley().destroy();
          $("#edit-contact form").parsley();

          // Fill data
          $.each(data.user, function (index, value) {
            if (index == 'notify_payments') {
              //if (val == true) {
                $("#edit-contact form #user-" + index).prop("checked", value);
              //}
            }
            else {
              $("#edit-contact form #user-" + index).val(value);
            }
          });

          $("#edit-contact").modal('show');

        })
          .fail(function () {
            // Show an error to the user
            $.gritter.add({
              title: 'Error:',
              text: 'There was an error loading the requested contact.',
              class_name: 'color danger'
            });
          });

      });
      return false;
    },

    filterChanges: function (field, isPhone) {
      if (config.filter_change == field) {
        // Show all
        $("#edit-distribution" + (isPhone ? '-phone' : '') + " #changes tbody tr").show();
        config.filter_change = false;
      } else {
        // Show selected filter only
        $("#edit-distribution" + (isPhone ? '-phone' : '') + " #changes tbody tr").hide();
        $("#edit-distribution" + (isPhone ? '-phone' : '') + " #changes tbody td:contains('" + field + "')").filter(function () {
          return $(this).text() === field
        }).closest('tr').show();
        config.filter_change = field;
      }
      return false;
    },

    isLoaded: function (type) {
      if (config.loaded[type] == true) {
        return true;
      }
      return false;
    },

    previewSignature: function (preview) {
      $('#preview-signature').find('iframe').attr('src', preview);
      $('#preview-signature').modal('show');
      return false;
    },

    sendSignature: function (id, rep, user) {
      $('#send-signature #signature_from').val(rep);
      $('#send-signature #signature_to').val(user);
      $('#send-signature #signature_id').val(id);
      $('#send-signature').modal('show');
      return false;
    }

  };

})();

