var Leads = (function () {
  'use strict';

  //Basic Config
  var config = {
  };

  /**
   * Check that the user is logged in and then run the callback function provided
   */
  function checkSession(callback) {
    // Check for a valid session
    $.get("/session", function(result) {
      // Run the callback function provided
      if (result.session == true) {
        callback();
      }
      // If not logged in, redirect to login page
      else {
        location.href="/login";
      }
    });
  }

  function leadsInit() {

    // Bind to the filter select change
    $('.filter-select').on('change', function(){
      $('#filter_form').trigger('submit');
    });

    $(".datetimepicker").datetimepicker({
      autoclose: true,
      componentIcon: '.mdi.mdi-calendar',
      todayBtn: true,
      navIcons:{
        rightIcon: 'mdi mdi-chevron-right',
        leftIcon: 'mdi mdi-chevron-left'
      }
    })
    .on('changeDate', function(e){
      //console.log($(this), $(this).hasClass('filter'));
      if ($(this).hasClass('filter')) {
        $('#filter_form').trigger('submit');
      }
    })
    .on('change', function(e){
      if ($(this).hasClass('filter') && $(this).val() == '') {
        $('#filter_form').trigger('submit');
      }
    });

  }

  return {
    //General data
    conf: config,

    //Init function
    init: function (options) {
      
      //Extends basic config with options
      $.extend( config, options );

      leadsInit();

    },

    // Get the lead details and open the edit modal
    editLead: function (lead_id) {
      var id = lead_id;
      checkSession(function(){
        // Pull the lead
        $.get("/leads/" + id + "/edit", function(data) {

          //console.log(data);
          
          if (data.success == false) {
            $.gritter.add({title: 'Error:', text: data.message, class_name: 'color danger'});
            return false;
          }

          // Fill the lead modal
          $("#edit-lead form").attr('action', '/leads/' + data.lead.id);

          // Clear all the fields just in case
          $("#edit-lead form")[0].reset();

          // Move back to the first tab
          $('#edit-lead form .tab-container a[href="#lead-contact"]').tab('show');

          // Reset the vehicle photo
          $("#edit-lead form #lead-vehicle img").hide();

          // Reset the distribution list
          $("#edit-lead form #lead-distribution .user-timeline").empty();

          // Reset the timeline
          $("#edit-lead form #lead-timeline .user-timeline").empty();

          // Just in case we need to reset parsley validation so that errors dont show from the last modal
          $("#edit-lead form").parsley().destroy();
          $("#edit-lead form").parsley();

          $.each(data.lead, function (index, value) {
            if (index=='id' || index=='status') {
              $("#edit-lead form #lead-"+index).html(value);
            }
            else if (index=='photo' && value != null) {
              $("#edit-lead form #lead-vehicle img").attr('src', 'https://carship.com/photos/' + value).show();
            }
            else if (index=='discount' || index=='lower' || index=='lift' || index=='inop') {
              $("#edit-lead form #lead-" + index).prop("checked", value);
            }
            else if (index=='distributions') {
              if (value != false) {
                var i=0;
                $.each(value, function (key, distro) {
                  var log_html = '';
                  if (distro.logs != false) {
                    $.each(distro.logs, function (log_key, log) {
                      log_html += (log_html != '' ? '<hr>' : '') + log.created_at + ' - ' + log.type + '<br><small>' + log.message + '</small>';
                    });
                  }
                  
                  var html = '<li class="' + (i == 0 ? 'latest' : '') + '"><div class="user-timeline-date">$' + distro.rate + '</div><div class="user-timeline-title"><a href="/companies/' + distro.company_id + '" target="_blank">' + distro.company + ' </a></div><div class="user-timeline-description">' + distro.id + ': ' + distro.created_at + ' - <a role="button" class="log text-muted" data-toggle="popover" data-content="' + log_html + '" data-container="body">' + distro.response + '</a></div></li>';
                  $("#edit-lead form #lead-distribution .user-timeline").append(html);
                  $("#edit-lead form #lead-distribution .user-timeline .log:last").popover({html: true, placement: 'right', title: 'Distribution Log:'});
                  i++;
                });
              }
            }
            else if (index=='timeline') {
              if (value != false) {
                var i=0;
                $.each(value, function (key, entry) {
                  var html = '<li class="' + (i == 0 ? 'latest' : '') + '"><div class="user-timeline-date">' + entry.date + '</div><div class="user-timeline-title">' + entry.title + '</div>' + (entry.description != false ? '<div class="user-timeline-description">' + entry.description + '</div></li>' : '');
                  
                  $("#edit-lead form #lead-timeline .user-timeline").append(html);
                  i++;
                });
              }
            }
            else {
              $("#edit-lead form #lead-" + index).val(value);
            }
          });

          $("#edit-lead").modal('show');

        })
        .fail(function() {
          // Show an error to the user
          $.gritter.add({title: 'Error:', text: 'There was an error loading the requested lead.', class_name: 'color danger'});
        });

      });
      return false;
    }

  };
 
})();