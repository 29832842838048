var CallReconcile = (function () {
  'use strict';

  //Basic Config
  var config = {
  };

  /**
   * Check that the user is logged in and then run the callback function provided
   */
  function checkSession(callback) {
    // Check for a valid session
    $.get("/session", function(result) {
      // Run the callback function provided
      if (result.session == true) {
        callback();
      }
      // If not logged in, redirect to login page
      else {
        location.href="/login";
      }
    });
  }

  function callReconcileInit() {

    $('input[type=checkbox]').on('click', function(){
      var phone_id = $(this).attr('data-phone');
      var total = 0;

      $.each($('.phone' + phone_id), function(key, element){
        total += $(element).prop('checked') ? Math.round($(element).attr('data-rate')) : 0;
      });

      $('#total' + phone_id).html('$' + (total / 100).toFixed(2));
    });

    // Bind to Parsley validation events
    $('#form-test').parsley().on('form:submit', function() {
      $('#submit-test').attr('disabled', 'disabled');
      $('#submit-test span').hide();
      $('#submit-test img').show();

      // Processing may take a few seconds, so don't allow any further clicks on modal
      $('#distributor, .md-close').on('click', function(e) {
        e.preventDefault();
        return false;
      });
    });
  }

  return {
    //General data
    conf: config,

    //Init function
    init: function (options) {

      //Extends basic config with options
      $.extend( config, options );

      callReconcileInit();

    }
  };

})();