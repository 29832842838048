var Distributor = (function () {
  'use strict';

  //Basic Config
  var config = {
  };

  /**
   * Check that the user is logged in and then run the callback function provided
   */
  function checkSession(callback) {
    // Check for a valid session
    $.get("/session", function(result) {
      // Run the callback function provided
      if (result.session == true) {
        callback();
      }
      // If not logged in, redirect to login page
      else {
        location.href="/login";
      }
    });
  }

  function distributorInit(filter) {
    // Bind to the company change event
    $('#filter_company').on('change', function (e) {
      e.preventDefault();

      var id = $(this).val();

      // Show/hide company distribution list
      if (id > 0 || id == 'ALL') {
        $('#distributions').show();
      }
      else {
        $('#distributions').hide();
      }

      // Set the company name
      if (id == 'ALL') {
        $('#title-company').html('');
        $('.c-name').show();

        // Show all rows
        $('#distributions table tbody tr').show();
      }
      else {
        $('.c-name').hide();
        $('#title-company').html('for ' + $(this).find('option:selected').text());

        // Show this company distributions and hide others
        $('#distributions table tbody tr').hide();
        $('tr.c' + id).show();
      }
    });

    // Make sure the company resets if they hit the back button
    $('#filter_company').val((filter ? filter : '')).trigger('change');

    // Bind to Parsley validation events
    $('#form-test').parsley().on('form:submit', function() {
      $('#submit-test').attr('disabled', 'disabled');
      $('#submit-test span').hide();
      $('#submit-test img').show();

      // Processing may take a few seconds, so don't allow any further clicks on modal
      $('#distributor, .md-close').on('click', function(e) {
        e.preventDefault();
        return false;
      });
    });
  }

  return {
    //General data
    conf: config,

    //Init function
    init: function (filter = false, options) {

      //Extends basic config with options
      $.extend( config, options );

      distributorInit(filter);

    },

    // Open modal options for distributor
    startDistributor: function (company_id, distribution_id) {
      //$('#distributor-submit').attr('disabled','disabled');

      $('.company_id').val(company_id);
      $('.distribution_id').val(distribution_id);

      $('#distributor').modal('show');
    }
  };

})();